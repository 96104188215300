/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import core from '@vedicium/core-vue';
import metronic from '@vedicium/metronic-vue';

import objectPath from 'object-path';
import { cloneDeep } from 'lodash';

import Abstract from '@/libs/classes/abstract';
import Device from '@/libs/classes/device';
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

export const SunbedErrors = [
  { error: 'E0', bit: 0, critical: true },
  { error: 'E1', bit: 1, critical: true },
  { error: 'E2', bit: 2, critical: true },
  { error: 'E3', bit: 3, critical: true },
  { error: 'E4', bit: 4, critical: true },
  { error: 'E5', bit: 5, critical: true },
  { error: 'E6', bit: 6, critical: true },
  { error: 'E7', bit: 7, critical: true },
  { error: 'E9', bit: 8, critical: true },
  { error: 'E10', bit: 9, critical: true },
  { error: 'E11', bit: 10, critical: true },
  { error: 'E12', bit: 11, critical: true },
  { error: 'E17', bit: 12, critical: true },
  { error: 'E18', bit: 13, critical: true },
  { error: 'E18_40', bit: null, critical: false },
  { error: 'E18_80', bit: null, critical: false },
  { error: 'E19', bit: 14, critical: true },
  { error: 'E19_10', bit: null, critical: true },
  { error: 'E19_20', bit: null, critical: true },
  { error: 'E20', bit: 15, critical: false },
  { error: 'E21', bit: 16, critical: false },
  { error: 'E22', bit: 17, critical: false },
  { error: 'E22_2', bit: 18, critical: false },
  { error: 'E22_3', bit: 19, critical: false },
  { error: 'E22_4', bit: 20, critical: false },
  { error: 'E22_5', bit: 21, critical: false },
  { error: 'E22_6', bit: 22, critical: false },
  { error: 'E22_7', bit: 23, critical: false },
  { error: 'E22_8', bit: 24, critical: false },
  { error: 'E22_9', bit: 25, critical: false },
  { error: 'E22_10', bit: 26, critical: false },
  { error: 'E24', bit: 27, critical: false },
  { error: 'E26', bit: 28, critical: true },
  { error: 'E29', bit: 29, critical: true },
  { error: 'E30', bit: 30, critical: true },
  { error: 'E31', bit: 31, critical: true },
  { error: 'E32', bit: 32, critical: false },
  { error: 'E34', bit: 33, critical: true },
  { error: 'E33', bit: 34, critical: false },
  { error: 'E35', bit: 35, critical: false },
  { error: 'E36', bit: 36, critical: true },
  { error: 'E37', bit: 40, critical: false },
  { error: 'E41', bit: 41, critical: false },
  { error: 'E42', bit: null, critical: true },
  { error: 'E42_1', bit: 42, critical: false },
  { error: 'E43', bit: null, critical: true },
  { error: 'E43_1', bit: 43, critical: false },
  { error: 'E44', bit: null, critical: true },
  { error: 'E44_1', bit: 44, critical: false },
  { error: 'E45', bit: null, critical: true },
  { error: 'E45_1', bit: 45, critical: false },
  { error: 'E46', bit: null, critical: true },
  { error: 'E46_1', bit: 46, critical: false },
  { error: 'E42_2', bit: 47, critical: false },
  { error: 'E43_2', bit: 48, critical: false },
  { error: 'E44_2', bit: 49, critical: false },
  { error: 'E45_2', bit: 50, critical: false },
  { error: 'E46_2', bit: 51, critical: false },
  { error: 'E42_3', bit: 52, critical: false },
  { error: 'E43_3', bit: 53, critical: false },
  { error: 'E44_3', bit: 54, critical: false },
  { error: 'E45_3', bit: 55, critical: false },
  { error: 'E46_3', bit: 56, critical: false },
  { error: 'E42_4', bit: 57, critical: false },
  { error: 'E43_4', bit: 58, critical: false },
  { error: 'E44_4', bit: 59, critical: false },
  { error: 'E45_4', bit: 60, critical: false },
  { error: 'E46_4', bit: 61, critical: false },
  { error: 'E62', bit: null, critical: false },
];

export const SunbedHealth = [
  {
    model: ['luxura_jewel', 'luxura_vegaz_8200', 'luxura_vegaz_9200'],
    temperatures: [
      {
        sensor: 'ntc3',
        modifyChartOptions (options) {
          const modifiedOptions = cloneDeep(options);

          // Set min/max of yaxis
          modifiedOptions.yaxis[0].min = 0;
          modifiedOptions.yaxis[0].max = 500;

          // Create annotations.yaxis if not exists
          objectPath.set(modifiedOptions, 'annotations.yaxis', objectPath.get(modifiedOptions, 'annotations.yaxis') || []);

          // Add critical temperature
          modifiedOptions.annotations.yaxis.push({
            y: 350,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.danger'),
                background: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              },
              text: 'Critical',
            },
          });

          return modifiedOptions;
        },
      },
      {
        sensor: 'ntc4',
        modifyChartOptions (options) {
          const modifiedOptions = cloneDeep(options);

          // Set min/max of yaxis
          modifiedOptions.yaxis[0].min = 0;
          modifiedOptions.yaxis[0].max = 500;

          // Create annotations.yaxis if not exists
          objectPath.set(modifiedOptions, 'annotations.yaxis', objectPath.get(modifiedOptions, 'annotations.yaxis') || []);

          // Add critical temperature
          modifiedOptions.annotations.yaxis.push({
            y: 350,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.danger'),
                background: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              },
              text: 'Critical',
            },
          });

          return modifiedOptions;
        },
      },
      {
        sensor: 'ntc1',
        modifyChartOptions (options) {
          const modifiedOptions = cloneDeep(options);

          // Create annotations.yaxis if not exists
          objectPath.set(modifiedOptions, 'annotations.yaxis', objectPath.get(modifiedOptions, 'annotations.yaxis') || []);

          // Add critical temperature
          modifiedOptions.annotations.yaxis.push({
            y: 700,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.danger'),
                background: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              },
              text: 'Critical',
            },
          });

          // Add optimal temperature annotation
          modifiedOptions.annotations.yaxis.push({
            y: 350,
            y2: 450,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
            fillColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.success'),
                background: metronic.libraries.config.layout.get('colors.theme.base.success'),
              },
              text: 'Optimal',
            },
          });

          // Add acceptable temperature annotations
          modifiedOptions.annotations.yaxis.push({
            y: 300,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.warning'),
                background: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              },
              text: 'Acceptable',
            },
          }, {
            y: 500,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.warning'),
                background: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              },
              text: 'Acceptable',
            },
          });

          return modifiedOptions;
        },
        key_figure: true,
        session_temperature: true,
      },
      {
        sensor: 'ntc2',
        modifyChartOptions (options) {
          const modifiedOptions = cloneDeep(options);

          // Create annotations.yaxis if not exists
          objectPath.set(modifiedOptions, 'annotations.yaxis', objectPath.get(modifiedOptions, 'annotations.yaxis') || []);

          // Add critical temperature
          modifiedOptions.annotations.yaxis.push({
            y: 700,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.danger'),
                background: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              },
              text: 'Critical',
            },
          });

          // Add optimal temperature annotation
          modifiedOptions.annotations.yaxis.push({
            y: 350,
            y2: 450,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
            fillColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.success'),
                background: metronic.libraries.config.layout.get('colors.theme.base.success'),
              },
              text: 'Optimal',
            },
          });

          // Add acceptable temperature annotations
          modifiedOptions.annotations.yaxis.push({
            y: 300,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.warning'),
                background: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              },
              text: 'Acceptable',
            },
          }, {
            y: 500,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.warning'),
                background: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              },
              text: 'Acceptable',
            },
          });

          return modifiedOptions;
        },
        key_figure: true,
      },
    ],
  },
  {
    model: ['luxura_x5', 'luxura_x7'],
    temperatures: [
      {
        sensor: 'ntc2',
        max: 350,
        modifyChartOptions (options) {
          const modifiedOptions = cloneDeep(options);

          // Set min/max of yaxis
          modifiedOptions.yaxis[0].min = 0;
          modifiedOptions.yaxis[0].max = 500;

          // Create annotations.yaxis if not exists
          objectPath.set(modifiedOptions, 'annotations.yaxis', objectPath.get(modifiedOptions, 'annotations.yaxis') || []);

          // Add critical temperature
          modifiedOptions.annotations.yaxis.push({
            y: 350,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.danger'),
                background: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              },
              text: 'Critical temperature',
            },
          });

          return modifiedOptions;
        },
      },
      {
        sensor: 'ntc3',
        max: 350,
        modifyChartOptions (options) {
          const modifiedOptions = cloneDeep(options);

          // Set min/max of yaxis
          modifiedOptions.yaxis[0].min = 0;
          modifiedOptions.yaxis[0].max = 500;

          // Create annotations.yaxis if not exists
          objectPath.set(modifiedOptions, 'annotations.yaxis', objectPath.get(modifiedOptions, 'annotations.yaxis') || []);

          // Add critical temperature
          modifiedOptions.annotations.yaxis.push({
            y: 350,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.danger'),
                background: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              },
              text: 'Critical temperature',
            },
          });

          return modifiedOptions;
        },
      },
      {
        sensor: 'ntc1',
        modifyChartOptions (options) {
          const modifiedOptions = cloneDeep(options);

          // Create annotations.yaxis if not exists
          objectPath.set(modifiedOptions, 'annotations.yaxis', objectPath.get(modifiedOptions, 'annotations.yaxis') || []);

          // Add critical temperature
          modifiedOptions.annotations.yaxis.push({
            y: 700,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.danger'),
                background: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              },
              text: 'Critical',
            },
          });

          // Add optimal temperature annotation
          modifiedOptions.annotations.yaxis.push({
            y: 350,
            y2: 450,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
            fillColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.success'),
                background: metronic.libraries.config.layout.get('colors.theme.base.success'),
              },
              text: 'Optimal',
            },
          });

          // Add acceptable temperature annotations
          modifiedOptions.annotations.yaxis.push({
            y: 300,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.warning'),
                background: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              },
              text: 'Acceptable',
            },
          }, {
            y: 500,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.warning'),
                background: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              },
              text: 'Acceptable',
            },
          });

          return modifiedOptions;
        },
        key_figure: true,
        session_temperature: true,
      },
      {
        sensor: 'ntc4',
        modifyChartOptions (options) {
          const modifiedOptions = cloneDeep(options);

          // Create annotations.yaxis if not exists
          objectPath.set(modifiedOptions, 'annotations.yaxis', objectPath.get(modifiedOptions, 'annotations.yaxis') || []);

          // Add critical temperature
          modifiedOptions.annotations.yaxis.push({
            y: 700,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.danger'),
                background: metronic.libraries.config.layout.get('colors.theme.base.danger'),
              },
              text: 'Critical',
            },
          });

          // Add optimal temperature annotation
          modifiedOptions.annotations.yaxis.push({
            y: 350,
            y2: 450,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
            fillColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.success'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.success'),
                background: metronic.libraries.config.layout.get('colors.theme.base.success'),
              },
              text: 'Optimal',
            },
          });

          // Add acceptable temperature annotations
          modifiedOptions.annotations.yaxis.push({
            y: 300,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.warning'),
                background: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              },
              text: 'Acceptable',
            },
          }, {
            y: 500,
            storeDashArray: 0,
            borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
            label: {
              borderColor: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              style: {
                color: metronic.libraries.config.layout.get('colors.theme.inverse.warning'),
                background: metronic.libraries.config.layout.get('colors.theme.base.warning'),
              },
              text: 'Acceptable',
            },
          });

          return modifiedOptions;
        },
        key_figure: true,
      },
    ],
  },
  {
    model: ['luxura_v6', 'luxura_v8'],
  },
];

export default class Equipment extends Abstract {
  static uri = '/v2/equipment';

  // Scheme
  app = null;

  serial = null;
  serial_submanufacturer = null;
  model = null;
  type = null;
  state = null;

  alias = null;

  device = {
    guid: null,
    serial: null,
    last_ping: null,
  };
  specifications = {
    colors: [],
    options: [],
  };
  location = {
    place_id: null,
    description: null,

    city: null,
    country: null,

    geometry: {
      lat: null,
      lon: null,
    },
    timezone: null,
    timezone_offset: null,
  };

  controller = {
    state: null,
    active_errors: null,

    serial: null,
    timestamp: null,

    logs: {
      parameter: null,
      boot: null,
      session: null,
      error: null,
      event: null,
    },

    communication: {
      timeout: null,
    },

    hours: [],
    release: {
      type: null,
      version: null,
      hash: null,
    },
  };

  settings = [];

  // Methods
  isDeviceLinked () {
    return !!(this.device && this.device.guid);
  }

  async getDevice (options = {}) {
    const document = await core.ws.get(`${Equipment.uri}/${this._meta.guid}/device`, { ...options });
    return new Device().merge(document);
  }

  async getLinkedCompanies (options = {}) {
    const documents = await core.ws.get(`${Equipment.uri}/${this._meta.guid}/companies`, { ...options });
    return (documents || []).map((document) => new Company().merge(document));
  }

  getColorOfLocation (location = null) {
    const equipmentColors = objectPath.get(this, 'specifications.colors');
    if (!equipmentColors) {
      return null;
    }

    const locationColor = equipmentColors.find((color) => color.location === location);
    return locationColor && locationColor.color;
  }

  getControllerHours (type = null) {
    if (!type || !this.controller || !this.controller.hours) {
      return null;
    }

    const hoursOfType = this.controller.hours.find((hour) => hour.type === type);
    if (!hoursOfType) {
      return null;
    }

    return hoursOfType.value;
  }

  // Getters & Setters
  get device_guid () {
    return (this.device && this.device.guid) || null;
  }

  get controllerRelease () {
    return (this.controller && this.controller.release) || null;
  }
}
